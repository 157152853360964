<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="上传中，请等待..."
  >
    <heads></heads>
    <banners></banners>
    <islogin />
    <div class="content">
      <div class="cons">
        <div class="cons-title">中图分类号推荐</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="cons-title-subtitle">
          <p>
            输入：标题、摘要、关键词。输出：中图分类号
          </p>
        </div>
        <div class="cons-form">
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="custom-block">
              标题、摘要、关键词为必填信息，用于推荐中图分类号。
            </div>
            <el-form-item label="标题" required prop="btword">
              <el-input v-model="form.btword" placeholder="请输入标题" @keyup.enter="onSubmit(ruleFormRef)"/>
            </el-form-item>
            <el-form-item label="摘要" required prop="zyword">
              <el-input
                v-model="form.zyword"
                type="textarea"
                placeholder="请输入摘要"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="关键词" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="请输入关键词，中间以逗号或空格或分号隔开"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="custom-block">以下为选填信息，用于提高推荐效果。</div>
          
            <el-form-item label="正文">
              <el-input
                v-model="form.zwword"
                placeholder="请输入正文"
                type="textarea"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="con-btn">
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/08-01.png", tips: "提交信息" },
  { url: "/img/shuoming/08-02.png", tips: "得到结果" },
]);
let srcList = reactive([
  "/img/shuoming/08-01.png",
  "/img/shuoming/08-02.png",
]);
// loading
let loading = ref(false);
let sbzczyval = ref("");
let form = reactive({
  btword: "",
  zyword: "",
  kwword: "",
  zwword: "",
  ckwx: "",
  writer: "",
  writerorg: "",
  jjkt: "",
  diqu: "",
  fbyt: "",
  sbzczy: "",
  sbzcjb: "",
  subxk: "",
});
const ruleFormRef = ref();

const baseRules = {
  btword: [{ required: true, message: "请输入标题", trigger: "blur" }],
  zyword: [{ required: true, message: "请输入摘要", trigger: "blur" }],
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};

const handleChange = (val) => {
  val.forEach((e, i) => {
    if (i == val.length - 1) {
      form.sbzczy += e;
    } else {
      form.sbzczy += e + ",";
    }
  });
};

// 提交代码
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      post("/journalCommon/SubZtflh", form)
        .then((res) => {
          loading.value = false;
          if (res.code === 200) {
            ElMessage({
              message: "提交成功！",
              duration: 1000,
              onClose: () => {
                router.push({
                  path: "/hyzg/res",
                  query: {
                    id: res.data,
                  },
                });
              },
            });
          } else {
            ElMessage({
              message: "提交失败：" + res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });

    
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
</style>
